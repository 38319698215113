/*
| Developed by Starton
| Filename : useShallowCompareMemoize.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import { useRef } from 'react'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type ObjectType = Record<string, unknown>

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/
const isShallowEqual = (objA: ObjectType, objB: ObjectType): boolean => {
	if (Object.is(objA, objB)) return true

	if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
		return false
	}

	const keysA = Object.keys(objA)
	const keysB = Object.keys(objB)

	if (keysA.length !== keysB.length) return false

	for (const key of keysA) {
		if (!Object.prototype.hasOwnProperty.call(objB, key) || !Object.is(objA[key], objB[key])) {
			return false
		}
	}

	return true
}

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useShallowCompareMemoize = <T,>(value: T): T => {
	const ref = useRef<T>(value)

	if (!isShallowEqual(value as ObjectType, ref.current as ObjectType)) {
		ref.current = value
	}

	return ref.current
}
