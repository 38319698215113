/*
| Developed by Dirupt
| Filename : useTwitterWidget.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react'
import { canUseDOM, cloneShallow, removeChildrenWithAttribute, twWidgetFactory } from '../utils/dashboard-utils'
import { onLoad } from '@/modules/dashboard/components'
import { useShallowCompareMemoize } from '@/hooks/useShallowCompareMemoize'

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const childDivIdentifyingAttribute = 'twdiv'

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export function useTwitterWidget(
	factoryFunctionName: 'createTimeline' | 'createTweet',
	primaryArg: Record<string, any>,
	options: Record<string, any>,
	onLoad: () => onLoad,
) {
	const [error, setError] = React.useState(null)
	const primaryArgMemoized = useShallowCompareMemoize(primaryArg)
	const optionsMemoized = useShallowCompareMemoize(options)
	const ref = React.useRef(null)

	// noop if ssr
	if (!canUseDOM) {
		return { ref, error }
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		setError(null)
		let isCanceled = false

		if (ref.current) {
			removeChildrenWithAttribute(ref.current, childDivIdentifyingAttribute)

			if (!ref || !ref.current) {
				return
			}

			const childEl = document.createElement('div')
			childEl.setAttribute(childDivIdentifyingAttribute, 'yes')
			// @ts-ignore
			ref.current.appendChild(childEl)

			twWidgetFactory()
				.then((wf) => {
					// @ts-ignore
					return wf[factoryFunctionName](cloneShallow(primaryArg), childEl, cloneShallow(options))
				})
				.then((resultMaybe) => {
					if (!resultMaybe && !isCanceled) {
						throw new Error(
							'Twitter could not create widget. If it is a Timeline or ' +
								'Tweet, ensure the screenName/tweetId exists.',
						)
					}

					if (!ref || !ref.current) {
						return
					}

					if (isCanceled) {
						if (childEl) {
							childEl.remove()
						}
						return
					}

					if (onLoad) {
						onLoad()
					}
				})
				.catch((e) => {
					console.error(e)
					setError(e)
				})
		}

		return () => {
			isCanceled = true
		}
	}, [factoryFunctionName, primaryArgMemoized, optionsMemoized, onLoad])

	return { ref, error }
}
