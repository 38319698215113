'use client'

import { Box, Typography, Grid2 as Grid, Stack, Paper, TypographyProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StatsCard = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(3),
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(1),
}))

const StatsValue = styled(Typography)<TypographyProps>(({ theme }) => ({
	fontSize: '2rem',
	fontWeight: 700,
	color: theme.palette.text.primary,
}))

const ChangeIndicator = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isPositive',
})<{ isPositive: boolean }>(({ theme, isPositive }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(0.5),
	color: isPositive ? theme.palette.success.main : theme.palette.error.main,
}))

/*
|--------------------------------------------------------------------------
| Types
|--------------------------------------------------------------------------
*/
type StatCardProps = {
	title: string
	value: string
	change: number
	period?: string
}

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/
const StatCard = ({ title, value, change, period = '24h' }: StatCardProps) => {
	const isPositive = change >= 0

	return (
		<StatsCard>
			<Typography color="text.secondary" variant="h5" component="p">
				{title}
			</Typography>
			<StatsValue variant="h4" component="p">
				{value}
			</StatsValue>
			<ChangeIndicator isPositive={isPositive}>
				{isPositive ? <TrendingUpIcon fontSize="small" /> : <TrendingDownIcon fontSize="small" />}
				<Typography variant="body2">
					{isPositive ? '+' : ''}
					{change}% ({period})
				</Typography>
			</ChangeIndicator>
		</StatsCard>
	)
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardDataOverview = () => {
	// Mock data - replace with real data from your API
	const stats = [
		{
			title: 'Total Value Locked',
			value: '$824,235.89',
			change: 12.5,
		},
		{
			title: 'Active Smart Contracts',
			value: '1,234',
			change: -2.3,
		},
		{
			title: 'Gas Price (GWEI)',
			value: '45.2',
			change: 5.7,
		},
		{
			title: 'Daily Transactions',
			value: '45,678',
			change: 8.9,
		},
	]

	return (
		<Stack spacing={3}>
			<Typography variant="h3" component="h2">
				Network Overview
			</Typography>

			<Grid container spacing={3}>
				{stats.map((stat, index) => (
					<Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
						<StatCard {...stat} />
					</Grid>
				))}
			</Grid>
		</Stack>
	)
}
