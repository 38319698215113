'use client'

import { Box, Typography, Stack, Paper } from '@mui/material'
import { LineChart, LineChartProps } from '@mui/x-charts/LineChart'
import { styled } from '@mui/material/styles'

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ChartContainer = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(3),
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(2),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardDataGraph = () => {
	// Mock data - replace with real API data
	const chartData = {
		xAxis: [
			{
				data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				scaleType: 'band' as const,
			},
		],
		series: [
			{
				data: [2400, 1398, 9800, 3908, 4800, 3800, 4300, 5300, 4100, 4800, 5100, 4900],
				label: 'Transactions',
				area: false,
				showMark: false,
			},
			{
				data: [1200, 3398, 3800, 8908, 6800, 7800, 6300, 7300, 6100, 5800, 4100, 3900],
				label: 'Active Users',
				area: false,
				showMark: false,
			},
		],
	} as LineChartProps

	return (
		<Stack spacing={3}>
			<Typography variant="h3" component="h2">
				Network Activity
			</Typography>

			<ChartContainer>
				<Box sx={{ width: '100%', height: 400 }}>
					<LineChart
						xAxis={chartData.xAxis}
						series={chartData.series}
						height={400}
						margin={{ top: 40, right: 40, bottom: 20, left: 50 }}
					/>
				</Box>
			</ChartContainer>
		</Stack>
	)
}
