/*
| Developed by Starton
| Filename : DashboardTwitterTimeline.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import React from 'react'
import { useTwitterWidget } from '@/modules/dashboard/hooks'
import { Box, styled, useTheme } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type onLoad = () => any
export type renderError = (error: Error) => React.ReactNode
export interface DashboardTwitterTimelineProps {
	dataSource: Record<string, any>
	options?: Record<string, any>
	onLoad?: onLoad
	renderError?: renderError
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const TwitterTimelineContainer = styled(Box)(({ theme }) => ({
	position: 'relative',
	backgroundColor: theme.palette.background.default,
	width: '100%',
	maxHeight: '400px',
	boxSizing: 'border-box',
	overflowX: 'hidden',
	overflowY: 'auto',

	[theme.breakpoints.up('lg')]: {
		borderRight: `1px solid ${theme.palette.divider}`,
		maxHeight: '100vh',
	},

	'& iframe': {
		backgroundColor: `${theme.palette.background.default} !important`,
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardTwitterTimeline: React.FC<DashboardTwitterTimelineProps> = ({
	dataSource,
	options,
	onLoad,
	renderError,
}) => {
	const theme = useTheme()
	const { ref, error } = useTwitterWidget(
		'createTimeline',
		dataSource,
		{
			width: '100%',
			chrome: 'transparent noscrollbar',
			theme: 'dark',
			dnt: true,
			linkColor: theme.palette.background.default,
		},
		onLoad || (() => {}),
	)

	return (
		<TwitterTimelineContainer ref={ref}>
			{error && renderError ? renderError(error) : null}
		</TwitterTimelineContainer>
	)
}
